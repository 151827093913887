
var imageWrap = $(".first-image");
imageWrap.imagesLoaded().progress(function () {
    $(".lds-ellipsis ").fadeOut();
    $(".preloader").animate({ height: 0 }, 400);

});


$(document).on("scroll", function () {
    if ($(document).scrollTop() > 100) {
        $("header").addClass("shrink");
    } else {
        $("header").removeClass("shrink");
    }
});



$('.js-input').blur(function () {
    if ($(this).val() != '') {
        $(this).parents('.form-field').addClass('has-value');
    }


    if (!$(this).val()) {
        $(this).parents('.form-field').removeClass('has-value');
    }
});




// navbar-toggler
$(".navbar-toggler").click(function () {  //use a class, since your ID gets mangled
    $("body").toggleClass("ov-h");
    $(this).find('i').toggleClass('fa-bars fa-times');
});

$(".accessibility-drop .dropdown-xs").click(function () {  //use a class, since your ID gets mangled
    $(this).find(".drop-hij").toggleClass("active");
    $(this).find('i').toggleClass('fa-caret-down fa-caret-up');
});

$(".nav-item.dropdown-xs").click(function () {  //use a class, since your ID gets mangled
    $(this).find(".drop-hij").toggleClass("active");
    $(this).find('i').toggleClass('fa-caret-down fa-caret-up');
    $(".navbar-collapse").toggleClass("mega-open");
});



var headerHeight = $("nav").height();


if ($(window).width() < 767) {
    $(".inner-page").css({
        "height": headerHeight + 1
    });
} else {
    $(".inner-page").css({
        "height": headerHeight + 17
    });

}



$(document).ready(function () {

    // INITIATE THE FOOTER
    siteFooter();
    // COULD BE SIMPLIFIED FOR THIS PEN BUT I WANT TO MAKE IT AS EASY TO PUT INTO YOUR SITE AS POSSIBLE
    $(window).resize(function () {
        siteFooter();
    });

    function siteFooter() {
        var siteContent = $('main');
        var siteContentHeight = siteContent.height();
        var siteContentWidth = siteContent.width();

        var siteFooter = $('footer');
        var siteFooterHeight = siteFooter.height();
        var siteFooterWidth = siteFooter.width();

        // console.log('Content Height = ' + siteContentHeight + 'px');
        // console.log('Content Width = ' + siteContentWidth + 'px');
        // console.log('Footer Height = ' + siteFooterHeight + 'px');
        // console.log('Footer Width = ' + siteFooterWidth + 'px');

        siteContent.css({
            "margin-bottom": siteFooterHeight + 0
        });
    };
});


if ($("#page-id").hasClass("inner-page")) {
    $("body").addClass("innerpage")
}

jQuery(document).ready(function ($) {
    // Get current path and find target link
    var path = window.location.pathname.split("/").pop();

    // Account for home page with empty path

    if (document.location.href.indexOf('/') === -1) {
        $('body').addClass("innerpage")
    }





    // Add active class to target link
});



$(function () {
    var $owl = $('.home-carousel'),
        effect = getAnimationName(),
        outIndex,
        isDragged = false;

    $owl.owlCarousel({
        margin: 0,
        navSpeed: 500,
        dragable: false,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        items: 1,
        animateIn: 'fake',
        animateOut: 'fake'
    });

    $owl.on('change.owl.carousel', function (event) {
        outIndex = event.item.index;
    });

    $owl.on('changed.owl.carousel', function (event) {
        var inIndex = event.item.index,
            dir = outIndex <= inIndex ? 'Next' : 'Prev';

        var animation = {
            moveIn: {
                item: $('.owl-item', $owl).eq(inIndex),
                effect: effect + 'In' + dir
            },
            moveOut: {
                item: $('.owl-item', $owl).eq(outIndex),
                effect: effect + 'Out' + dir
            },
            run: function (type) {
                var animationEndEvent = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',
                    animationObj = this[type],
                    inOut = type == 'moveIn' ? 'in' : 'out',
                    animationClass = 'animated owl-animated-' + inOut + ' ' + animationObj.effect,
                    $nav = $owl.find('.owl-prev, .owl-next, .owl-dot, .owl-stage');

                $nav.css('pointerEvents', 'none');

                animationObj.item.stop().addClass(animationClass).one(animationEndEvent, function () {
                    // remove class at the end of the animations
                    animationObj.item.removeClass(animationClass);
                    $nav.css('pointerEvents', 'auto');
                });
            }
        };

        if (!isDragged) {
            animation.run('moveOut');
            animation.run('moveIn');
        }
    });

    $owl.on('drag.owl.carousel', function (event) {
        isDragged = true;
    });

    $owl.on('dragged.owl.carousel', function (event) {
        isDragged = false;
    });

    /**
     * Get Animation Name from the class 'owl-carousel',
     * animation name begins with fx...
     */
    function getAnimationName() {
        var re = /fx[a-zA-Z0-9\-_]+/i,
            matches = re.exec($owl.attr('class'));

        return matches !== null ? matches[0] : matches;
    }


    /**
     * For Demo (Selectbox)
     * Change select options with animation name
     */
    $('#fxselect').on('change', function (e) {
        var $owlCarousel = $('.owl-carousel'),
            animationName = getAnimationName();
        effect = $(this).find('option:selected').val();

        //remove old root class
        if (animationName !== null) {
            $owl.removeClass(animationName);
        }

        //add new root class
        $owlCarousel.addClass(effect);
    });
});




// search-trigger


$(function () {                       //run when the DOM is ready
    $(".search-trigger").click(function () {  //use a class, since your ID gets mangled
        // $(this).find('i').toggleClass('fa-search fa-times');
        $(this).hide();
        $('#Search_Field').focus();
        $(".search-pop").toggleClass("active");
        $(".overlay").fadeToggle();

        setTimeout(function () {
            $(".trending").fadeToggle();

        }, 300);

    });


    $(".lang").click(function () {  //use a class, since your ID gets mangled
        $("body").toggleClass("arabic");

    });



    // 



    $(".color-btn").click(function (event) {
        event.preventDefault();
        $("body").removeClass("dark-mode");
        $(".contrast-btn").removeClass("active");
        $(".color-btn").addClass("active")
    });


    $(".contrast-btn").click(function (event) {
        $("body").addClass("dark-mode");
        event.preventDefault();
        $(".color-btn").removeClass("active");
        $(".contrast-btn").addClass("active")
    });



    $(".overlay").click(function () {  //use a class, since your ID gets mangled

        setTimeout(function () {
            $(".overlay").fadeToggle();
            $(".search-trigger").show();
            $(".search-pop").toggleClass("active");

        }, 300);


        $(".trending").fadeToggle();
    });



});

var $owlCarousel = $(".carousel");

if ($owlCarousel.length) {
    $owlCarousel.each(function () {

        var items = parseInt($(this).attr("data-owl-items"), 10);
        if (!items) items = 1;

        var nav = parseInt($(this).attr("data-owl-nav"), 2);
        if (!nav) nav = 0;

        var dots = parseInt($(this).attr("data-owl-dots"), 2);
        if (!dots) dots = 0;

        var center = parseInt($(this).attr("data-owl-center"), 2);
        if (!center) center = 0;

        var loop = parseInt($(this).attr("data-owl-loop"), 2);
        if (!loop) loop = 0;

        var margin = parseInt($(this).attr("data-owl-margin"), 20);
        if (!margin) margin = 0;


        var autoWidth = parseInt($(this).attr("data-owl-auto-width"), 2);
        if (!autoWidth) autoWidth = 0;

        var navContainer = $(this).attr("data-owl-nav-container");
        if (!navContainer) navContainer = 0;

        var autoplay = parseInt($(this).attr("data-owl-autoplay"), 2);
        if (!autoplay) autoplay = 0;

        var autoplayTimeOut = parseInt($(this).attr("data-owl-autoplay-timeout"), 10);
        if (!autoplayTimeOut) autoplayTimeOut = 5000;

        var autoHeight = parseInt($(this).attr("data-owl-auto-height"), 2);
        if (!autoHeight) autoHeight = 0;

        var fadeOut = $(this).attr("data-owl-fadeout");
        if (!fadeOut) fadeOut = 0;
        else fadeOut = "fadeOut";

        if ($("body").hasClass("rtl")) var rtl = true;
        else rtl = false;

        if (items === 1) {
            $(this).owlCarousel({
                navContainer: navContainer,
                animateOut: fadeOut,
                autoplayTimeout: autoplayTimeOut,
                autoplay: autoplay,
                autoheight: autoHeight,
                center: center,
                loop: loop,
                margin: margin,
                autoWidth: autoWidth,
                items: 1,
                nav: nav,
                dots: dots,
                rtl: rtl,
                navText: ['<img src="assets/img/arrow-left.svg" alt="">', '<img src="assets/img/arrow-right.svg" alt="">'],

            });
        }
        else {
            $(this).owlCarousel({
                navContainer: navContainer,
                animateOut: fadeOut,
                autoplayTimeout: autoplayTimeOut,
                autoplay: autoplay,
                autoheight: autoHeight,
                center: center,
                loop: loop,
                margin: margin,
                autoWidth: autoWidth,
                items: 1,
                nav: nav,
                dots: dots,
                rtl: rtl,
                navText: ['<img src="assets/img/arrow-left.svg" alt="">', '<img src="assets/img/arrow-right.svg" alt="">'],

                responsive: {
                    1368: {
                        items: items
                    },
                    992: {
                        items: 3
                    },
                    450: {
                        items: 2
                    },
                    0: {
                        items: 1
                    }
                }
            });
        }

        if ($(this).find(".owl-item").length === 1) {
            $(this).find(".owl-nav").css({ "opacity": 0, "pointer-events": "none" });
        }

    });
}

// popup

var $popupImage = $(".popup-image");

if ($popupImage.length > 0) {
    $popupImage.magnificPopup({
        type: 'image',
        fixedContentPos: false,
        gallery: { enabled: true },
        removalDelay: 300,
        mainClass: 'mfp-fade',
        callbacks: {
            // This prevents pushing the entire page to the right after opening Magnific popup image
            open: function () {
                $(".page-wrapper, .navbar-nav").css("margin-right", getScrollBarWidth());
            },
            close: function () {
                $(".page-wrapper, .navbar-nav").css("margin-right", 0);
            }
        }
    });
}

var $videoPopup = $(".video-popup");

if ($videoPopup.length > 0) {
    $videoPopup.magnificPopup({
        type: "iframe",
        removalDelay: 300,
        mainClass: "mfp-fade",
        overflowY: "hidden",
        iframe: {
            markup: '<div class="mfp-iframe-scaler">' +
                '<div class="mfp-close"></div>' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                '</div>',
            patterns: {
                youtube: {
                    index: 'youtube.com/',
                    id: 'v=',
                    src: '//www.youtube.com/embed/%id%?autoplay=1'
                },
                vimeo: {
                    index: 'vimeo.com/',
                    id: '/',
                    src: '//player.vimeo.com/video/%id%?autoplay=1'
                },
                gmaps: {
                    index: '//maps.google.',
                    src: '%id%&output=embed'
                }
            },
            srcAction: 'iframe_src'
        }
    });
}



$(document).ready(function () {

    $('.collapse').on('shown.bs.collapse', function () {
        $(this).prev().addClass('active-acc');

    });

    $('.collapse').on('hidden.bs.collapse', function () {
        $(this).prev().removeClass('active-acc');
    });

});




$(".lab1").on("click", function () {

    // file 
    var input = document.getElementById('file-upload');
    var infoArea = document.getElementById('file-upload-filename');

    input.addEventListener('change', showFileName);

    function showFileName(event) {

        // the change event gives us the input it occurred in 
        var input = event.srcElement;

        // the input has an array of files in the `files` property, each one has a name that you can use. We're just using the name here.
        var fileName = input.files[0].name;

        // use fileName however fits your app best, i.e. add it into a div
        infoArea.textContent = 'File name: ' + fileName;
    }

});




$(".lab2").on("click", function () {
    var input2 = document.getElementById('file-upload2');
    var infoArea2 = document.getElementById('file-upload-filename2');

    input2.addEventListener('change', showFileName2);

    function showFileName2(event) {

        // the change event gives us the input it occurred in 
        var input2 = event.srcElement;

        // the input has an array of files in the `files` property, each one has a name that you can use. We're just using the name here.
        var fileName2 = input2.files[0].name;

        // use fileName however fits your app best, i.e. add it into a div
        infoArea2.textContent = 'File name: ' + fileName2;
    }
    // file end


});


$(".lab3").on("click", function () {
    var input3 = document.getElementById('file-upload3');
    var infoArea3 = document.getElementById('file-upload-filename3');

    input3.addEventListener('change', showFileName3);

    function showFileName3(event) {

        // the change event gives us the input it occurred in 
        var input3 = event.srcElement;

        // the input has an array of files in the `files` property, each one has a name that you can use. We're just using the name here.
        var fileName3 = input3.files[0].name;

        // use fileName however fits your app best, i.e. add it into a div
        infoArea3.textContent = 'File name: ' + fileName3;
    }
    // file end

});



// accessibility 

var $affectedElements = $("p, .resizable, .nav-link, a, h4, h5, label"); // Can be extended, ex. $("div, p, span.someClass")

// Storing the original size in a data attribute so size can be reset
$affectedElements.each(function () {
    var $this = $(this);
    $this.data("orig-size", $this.css("font-size"));
});

var i = 0;


$(".a-plus").click(function () {
    i++;
    // console.log(i);
    if (i >= 2) {
        i = 0;
        $('.a-plus').prop('disabled', true);
    }

    changeFontSize(1);
})

$(".a-minus").click(function () {
    i++;
    // console.log(i);
    if (i == 2) {
        i = 0;
        $('.a-minus').prop('disabled', true);
    }


    changeFontSize(-1);
})

$(".a-normal").click(function () {
    $affectedElements.each(function () {
        var $this = $(this);
        $('.a-minus').prop('disabled', false);
        $('.a-plus').prop('disabled', false);
        $this.css("font-size", $this.data("orig-size"));
    });
})

function changeFontSize(direction) {
    $affectedElements.each(function () {
        var $this = $(this);
        $this.css("font-size", parseInt($this.css("font-size")) + direction);
    });
}